import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ButtonAppBar from './fragments/ButtonAppBar'
import PositionedMenu from './fragments/PositionedMenu'
import Search from './fragments/Search'
import FeatureCard from './fragments/FeatureCard'
import DashboardComponent from './fragments/DashboardComponent'
import JobCard from './fragments/JobCard'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

function Home() {
    return (
        <>
            <Container>
                <Grid >
                    <Grid item xs={12} md={6}>
                        <Box>
                            <ButtonAppBar />
                        </Box>
                    </Grid>
                </Grid>
                {/* More than 5000+ jobs component ------------------ */}
                <Grid container >
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{fontFamily:'cursive',mt:20,fontSize:'55px'}} >
                                Discover<br></br>
                                more than<br></br>
                                <span style={{color:'#26A4FF'}} > 5000+ Jobs</span>
                                </Typography>
                                <img  style={{maxWidth:'50%'}} src='https://assets-global.website-files.com/6480217dd2b60074b15929c5/6482d9e701243d4ce022dcb9_Homepage%20Hero%20Title%20Pattern.png' />
                        </Box>
                        <Grid item xs={12} md={9}>
                    <Typography sx={{fontSize:'18px'}} >Great platform for the job seeker that searching for new career heights and passionate about startups.</Typography>
                </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: { xs: 'none', lg: 'block' } }} >
                        <Box>
                            <img style={{ maxWidth: '102%' }} src='https://imgs.search.brave.com/u5W3I3Rz9-O1o0c-Jw7uoV6dX1I_iW_F3rYtc3zkJIY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly93d3cu/cG5nbWFydC5jb20v/ZmlsZXMvMTUvU21p/bGluZy1CdXNpbmVz/cy1NYW4tU3RhbmRp/bmctUE5HLVBob3Rv/cy5wbmc' />
                        </Box>
                    </Grid>
                </Grid>
                
                {/* Company Logos ------------------ */}
                <Grid item xs={12}>
                    <Typography className='light-grey'>Companies We Helped to Grow</Typography>
                </Grid>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={6} sm={3} container justifyContent="center">
                        <Box display="flex" justifyContent="center">
                            <img style={{ maxWidth: '50%', height: 'auto' }} src='https://imgs.search.brave.com/oKM2BlLuA-2f7Ja7Y_t2X5UFwHWtkw9zpYkssZp56lc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9icmFu/ZHNsb2dvcy5jb20v/d3AtY29udGVudC91/cGxvYWRzL2ltYWdl/cy92b2RhZm9uZS1s/b2dvLTEucG5n' alt="Logo 1" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} container justifyContent="center">
                        <Box display="flex" justifyContent="center">
                            <img style={{ maxWidth: '50%', height: 'auto' }} src='https://imgs.search.brave.com/FwWt7mbv8CILB4kJRdW_UiU79RB3AdYMozSsNxdxSkA/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWN3/aWtpLmV0c2kub3Jn/L2ltYWdlcy9JbnRl/bC1sb2dvLTIwMjIu/cG5nPzIwMjMwMzEw/MDkyMDUx' alt="Logo 2" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} container justifyContent="center">
                        <Box display="flex" justifyContent="center">
                            <img style={{ maxWidth: '50%', height: 'auto' }} src='https://imgs.search.brave.com/ki7faeB0GRO3Eor8AU7pT-cobCIqYU7v3ukyj8HgCKw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/cy13b3JsZC5uZXQv/d3AtY29udGVudC91/cGxvYWRzLzIwMjAv/MTAvVGVzbGEtTG9n/by03MDB4Mzk0LnBu/Zw' alt="Logo 3" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} container justifyContent="center">
                        <Box display="flex" justifyContent="center">
                            <img style={{ maxWidth: '50%', height: 'auto' }} src='https://imgs.search.brave.com/30lC2hdJWF14v3R49emtF4g0PIQElW6A-liDHAI9v5M/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9icmFu/ZHNsb2dvcy5jb20v/d3AtY29udGVudC91/cGxvYWRzL2ltYWdl/cy9hbWQtbG9nby5w/bmc' alt="Logo 4" />
                        </Box>
                    </Grid>
                </Grid>
                {/* Featured Jobs ----------------------------- */}
                <Grid item xs={12} md={12}>
                    <Box>
                    <Typography sx={{fontSize:'32px'}} >Explore by <span style={{color:'#26A4FF'}} >Catogory</span></Typography>
                    </Box>
                </Grid>
                <Grid container  sx={{mt:3}} spacing={2} >
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <FeatureCard/>
                        </Box>
                    </Grid>
                </Grid>
            <Grid>
                <Grid item xs={12} md={12}>
                    <Box>
                        <DashboardComponent/>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mt: 5, mb: 2 }} alignItems="center">
      {/* Featured Jobs - Left Aligned */}
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Typography sx={{ fontSize: '32px' }}>
            Featured <span style={{ color: '#26A4FF' }}>Jobs</span>
          </Typography>
        </Box>
      </Grid>

      {/* Show All Jobs - Right Aligned with Arrow */}
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-end' }} alignItems="center">
          <Typography sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
            Show all jobs
            <ArrowForwardOutlinedIcon sx={{ ml: 1 }} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <JobCard/>
                    </Box>
                </Grid>
            </Grid>


            </Container>
        </>
    )
}

export default Home