import { Grid, IconButton, Typography, Box } from '@mui/material';
import React from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

function FeatureCard() {
    return (
        <Grid container>
        <Grid item xs={12} md={12}>
            <Box 
                sx={{ 
                    border: '1px solid #CCCCF5', 
                    borderRadius: 2, 
                    p: 2, 
                    mb:5,
                    mt:2,
                    '&:hover': { 
                        bgcolor:'#5235E8',  
                        color: 'white',
                        '& svg, & h6, & p': {
                            color: 'white',
                        }
                    } 
                }} 
            >
                {/* Icon */}
                <svg 
                    style={{ maxWidth: '40px', marginBottom: '10px', marginTop: '20px', color: '#6b6bff' }} 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 512 512"
                >
                    <path fill="currentColor" d="M469.3 19.3l23.4 23.4c25 25 25 65.5 0 90.5l-56.4 56.4L322.3 75.7l56.4-56.4c25-25 65.5-25 90.5 0zM44.9 353.2L299.7 98.3 413.7 212.3 158.8 467.1c-6.7 6.7-15.1 11.6-24.2 14.2l-104 29.7c-8.4 2.4-17.4 .1-23.6-6.1s-8.5-15.2-6.1-23.6l29.7-104c2.6-9.2 7.5-17.5 14.2-24.2zM249.4 103.4L103.4 249.4 16 161.9c-18.7-18.7-18.7-49.1 0-67.9L94.1 16c18.7-18.7 49.1-18.7 67.9 0l19.8 19.8c-.3 .3-.7 .6-1 .9l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l64-64c.3-.3 .6-.7 .9-1l45.1 45.1zM408.6 262.6l45.1 45.1c-.3 .3-.7 .6-1 .9l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l64-64c.3-.3 .6-.7 .9-1L496 350.1c18.7 18.7 18.7 49.1 0 67.9L417.9 496c-18.7 18.7-49.1 18.7-67.9 0l-87.4-87.4L408.6 262.6z"/>
                </svg>

                {/* Title */}
                <Typography variant="h6" sx={{ mb: 1, mt: 4 }}>
                    Design
                </Typography>

                {/* Subtitle and Arrow */}
                <Grid container alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="body2" color="text.secondary">
                            235 Jobs Available
                        </Typography>
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end">
                        <IconButton size="small" sx={{ color: 'inherit' }}>
                            <ArrowForwardOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        </Grid>
    );
}

export default FeatureCard;
