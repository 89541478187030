import React from "react";
import XAutoComplete from "./XAutoComplate"
import './style.css'
import ImgInput from "./ImgInput";


const Input = React.forwardRef((props, ref) => {
    var objectConstructor = ({}).constructor;
    const { panel } = props
    const panelCss = panel ? { border: 'none' } : {}
    // React.useEffect(() => {
    //     console.log("INSIDE ", props?.options)
    // }, [props?.options])
    if (props?.type == 'textarea') {
        return <div className="did-floating-label-content" style={{ marginBottom: props?.style?.marginBottom, ...panelCss }}>
            <textarea ref={ref} className="did-floating-input" {...props} placeholder=" " style={{ paddingTop: 6, height: 100 }}></textarea>
            <label className="did-floating-label">{props?.label ? props?.label : props?.name}</label>
        </div>

    }
    if (props?.type == 'image') {
        return <ImgInput {...props} />
    }
    if (props?.type == 'autocomplete') {
        return <XAutoComplete {...props} />
    }

    if (props?.type == 'select') {
        return <div className="did-floating-label-content" style={{ marginBottom: props?.style?.marginBottom, ...panelCss }}>
            <select ref={ref} className="did-floating-input" {...props} >
                <option>Select {props?.label ? props?.label : props?.name}</option>
                {props?.options?.map((option,i) => objectConstructor == option.constructor ? <option key={i} value={option[props?.val]}>{option[props?.text]}</option> : <option>{option}</option>)}
            </select>
        </div>
    }
    if (props?.type === 'hidden') {
        return <input ref={ref} {...props} />
    }
    if (props?.type === 'checkbox' || props?.type === 'radio') {
        return <div style={{ marginBottom: props?.style?.marginBottom }}>
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                <input ref={ref}  {...props} style={{ padding: 0 }} />
                <label style={{ position: 'relative' }}>
                    {props?.label ? props?.label : props?.name}
                </label>
            </div>
        </div>
    }
    return <div className="did-floating-label-content" style={{ marginBottom: props?.style?.marginBottom }}>
        <input ref={ref}  className="did-floating-input" {...props} style={panelCss} placeholder=" " autoComplete="false"
            />
        <label className="did-floating-label">{props?.label ? props?.label : props?.name}</label>
    </div>

})
export default Input