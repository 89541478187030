import * as React from "react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
  Grid,
  Paper,
  Avatar,
  Typography,
  Link,
  Box
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { XForm, ZButton } from "../../libs/Libs";
import { REQUESTS } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";
import { css as customCss } from '../../content/Style'
const Login = ({ title, hasForgot, hasRegister, submitLabel }) => {
  title = title ? title : "Sign In";
  submitLabel = submitLabel ? submitLabel : "Sign In";
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const paperStyle = {
    padding: 20,
  };
  const avatarStyle = { backgroundColor: "#1095bbfc" };
  const btnStyle = { margin: "8px 0px" };
  return (<>
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ 'height': '90vh' }}
    >

      <Grid item align="center" alignItems="center" xs={12} md={3} xl={3}>

        <Box sx={{ ...customCss.Form }}>
          {loading && <LinearProgress sx={{ my: 1 }} />}
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>{title}</h2>
          <XForm
            onSubmit={(e, data) => {
              setLoading(true)
              dispatch({
                type: REQUESTS.LOGIN.POST.ACTION,
                payload: data,
                cache: true,
                callback: (data) => {
                  setLoading(false)
                  if (data.token) {
                    navigate("/dashboard")
                  }
                }
              });

            }}
          >
            <TextField
              fullWidth
              required
              variant="standard"
              label="Username"
              name="username"
              placeholder="Enter Username"
            />
            <TextField
              fullWidth
              required
              variant="standard"
              label="Password"
              name="password"
              placeholder="Enter Password"
              type="password"
            />

            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember Me"
            />
            <ZButton
              fullWidth
              loading={loading}
              title={submitLabel}
              bgcolor={"green"}
              type={"submit"}
            />
          </XForm>

          <Typography sx={{ fontSize: 12, p: 2 }}>
            <span onClick={() => { navigate("/reset/") }}>Forgot Password ?</span>
          </Typography>


          {hasRegister && (
            <Typography>
              {" "}
              Do you have an account ?<Link href="#">Sign Up</Link>
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  </>
  );
};
export default Login;
