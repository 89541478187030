import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box, Button
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { css as customCss } from '../content/Style'

export default function ZModal({
    title,
    children,
    showModal,
    onAction,
    successBtn,
    maxWidth,
}) {
    let ref = React.createRef();
    let refReset = React.createRef();
    const [fullScreen, setFullScreen] = React.useState(true);
    onAction = onAction?onAction:(action)=>{}
    return (
        <>
            <Dialog
                open={showModal && children}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth={maxWidth?maxWidth:"xs"}
            
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    sx={{ p: 0.5, px: 1, ...customCss.Card.header }}
                >
                    <Typography variant="caption">{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ bgcolor: "background.paper" }}>
                        {children}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{onAction("Cancel")}}>Cancel</Button>
                    {successBtn && <Button onClick={()=>{onAction("Save")}}>{successBtn}</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}
