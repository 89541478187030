import {SignalWifiConnectedNoInternet4Outlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {css} from '../../content/Style'

export default function Details(props) {
    return <Box
        direction="row"
        justifyContent="center"
        sx={{ height: '100vh', display: 'flex',  justifyContent: 'center'}}>
        <Box alignItems='center' sx={{textAlign:'center', py:'40vh'}}>
            <SignalWifiConnectedNoInternet4Outlined sx={{fontSize:40,color:css.Colors[3]}}/>
            <Typography>Your are offline , Please check your Internet</Typography>
        </Box>
    </Box>
}