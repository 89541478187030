import React from 'react'
import {Input,XForm} from '../../libs/Libs'
import { Button, Grid } from '@mui/material'
import {css as customCss} from '../../content/Style'





const Registration = () => {
  return (
    <Grid container>
      <Grid xs={12} md={6}>
    
    <div style={{maxWidth:'600px',margin:'0 auto',padding:'20px',border:'1px solid #ddd',borderRadius:'8px',backgroundColor:'#F5F5F5'}}>
      <h2 style={{margin:'0 0 10px 0',fontFamily:'sans-serif'}}>Social Media Assistant</h2>
      <p >Nomad &bull; Paris, France &bull; Full-Time</p>
      <div style={{width:'100%',height:'1px',backgroundColor:'#C7C8CC',marginTop:'30px'}}/>
      <XForm 
        onSubmit={ (e,data)=> console.log(data)}
      >
      <h3 style={{margin:'0 0 10px 0',fontFamily:'sans-serif',marginTop:'20px'}}>Submit your application</h3>
        <p >The following is required and will only be shared with Nomad</p>

        
          <Input name='full_name' label='Full Name'/>
     
          
          <Input name='email_adress' label='Email Adress '/>
     
          
          <Input name='phone_number' label='Phone Number '/>
       
          
          <Input name='job_title' label=' Whats your current or previous job title'/>
        
        <div style={{width:'100%',height:'1px',backgroundColor:'#C7C8CC',marginTop:'30px'}}/>
        <h3 >Links</h3>
        
          
          <Input name='linkedin_url' label='LinkedIn URL'/>
       
        
          
          <Input name='portfolio_url' label='Portfolio URL '/>
        
        
        <label style={{fontFamily:'system-ui'}}>Additional Information</label>
        <Input type='textarea' style={{width:'100%'}}/>
        <p style={{fontFamily:'system-ui'}}>Maximum 500 characters</p>
       
        
          <label style={{fontFamily:'system-ui'}}>Attach Your Resume : </label>
          <Input name='attach_redume' type='file' style={{paddingLeft:'68%',cursor:'pointer'}}/>
        
        <div style={{width:'100%',height:'1px',backgroundColor:'#C7C8CC',marginTop:'30px'}}/>
        <Button type='submit' style={customCss.Btn.Success}> submit Application</Button>
        <p>By sending the request you can confirm that you accept our <a style={{color:'#86469C',cursor:'pointer',fontWeight:'bold'}}>Terms of Service</a> and <a style={{color:'#86469C',cursor:'pointer',fontWeight:'bold'}}>Privacy Policy</a></p>
      </XForm>
    </div>
    </Grid>
  </Grid>
  )
  

  
}

export default Registration