import { createAction } from './saga'
export const AUTH_DATA = "auth";

// ---  Your Part Do not update above code----

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ISLOGGED = "ISLOGGED";
export const GROUP = "GROUP";
export const USERGROUP = "USERGROUP";
export const DASHBOARD = "DASHBOARD";
export const USER = "USER";
export const FORGOT = "FORGOT";
export const BRANCH = "BRANCH";
export const LOAN = "LOAN";
export const FILEUPLOAD = "FILEUPLOAD";
export const CENTER = "CENTER";
export const AGENT = "AGENT";
export const CUSTOMER = "CUSTOMER";
export const AGENTREPORT = "AGENTREPORT";
export const BRANCHREPORT = "BRANCHREPORT";


export const MAIL = "MAIL";
export const REQUESTS = {
    ...createAction(LOGOUT, "", AUTH_DATA),
    ...createAction(ISLOGGED, "", AUTH_DATA),
    ...createAction(LOGIN, "token", AUTH_DATA),
    ...createAction(GROUP, "group", "group",{success:"User group updated",error:"Failed to add group"}),
    ...createAction(USERGROUP, "user/group", "usergroup",{success:"User group changed",error:"Failed to change group"}),
    ...createAction(DASHBOARD, "dashboard", "dashboard"),
    ...createAction(USER, "user", "user",{success:"User updated successfully",error:"Failed to add user"}),
    ...createAction(FORGOT, "forgotpassword", "forgot"),
    ...createAction(BRANCH, "branch", "branch",{success:"branch updated successfully",error:"Failed to add branch"}),
    ...createAction(LOAN, "loan", "loan",{success:"Loan updated successfully",error:"Failed to add Loan"}),
    ...createAction(CENTER, "center", "center",{success:"Center updated successfully",error:"Failed to add Center"}),
    ...createAction(AGENT, "agent", "agent",{success:"Agent updated successfully",error:"Failed to add Agent"}),
    ...createAction(CUSTOMER, "customer", "customer",{success:"Customer updated successfully",error:"Failed to add customer"}),
    ...createAction(FILEUPLOAD, "fileupload", "fileupload"),
    ...createAction(MAIL, "mail", "mail"),
    ...createAction(AGENTREPORT, "agent/report", "agentreport"),
    ...createAction(BRANCHREPORT, "branch/report", "branchreport"),
}