import React from 'react';
import { Card, CardContent, Typography, Box, Chip,Grid } from '@mui/material';

const JobCard = () => {
  return (
    <Grid container>
        <Grid item xs={12} md={12}>
<Box sx={{border:'1px solid #CCCCF5',mt:5,mb:2}}>
        
    
      <CardContent>
        {/* Company Logo and Job Type */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2,mt:5 }}>
          <img src="https://imgs.search.brave.com/Yk3pO_ZsEavXLn2V3Cz8fC4iAyXLcMV5p-F64IiwcgM/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMtMDAuaWNvbmR1/Y2suY29tL2Fzc2V0/cy4wMC9kcm9wYm94/LWljb24tMjU2eDIx/Ny1ycW45bWFlNC5w/bmc" 
          alt="Company Logo" style={{ maxWidth:'35px' }} />
          <Chip label="Full Time" variant="outlined" sx={{ color: '#4D49FF', borderColor: '#4D49FF', fontWeight: 'bold' }} />
        </Box>

        {/* Job Title */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2,mt:2 }}>
          Brand Designer
        </Typography>

        {/* Company Name and Location */}
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
          Dropbox • Madrid, Spain
        </Typography>

        {/* Job Description */}
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 4 }}>
          Dropbox is looking for Brand team to help team ...
        </Typography>

        {/* Job Tags */}
        <Box sx={{ display: 'flex', gap: 1,mb:3 }}>
          <Chip label="Marketing" sx={{ backgroundColor: '#FFF5E5', color: '#FFA726' }} />
          <Chip label="Design" sx={{ backgroundColor: '#E5F6F5', color: '#26C6DA' }} />
        </Box>
      </CardContent>
    </Box>
    </Grid>
    </Grid>
  );
};

export default JobCard;
