import SpaceDashboardSharpIcon from "@mui/icons-material/SpaceDashboardOutlined";
import LineStyleSharpIcon from "@mui/icons-material/LineStyleSharp";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import Group from '../pages/adminstration/group'
import UserPermission from '../pages/adminstration/user_permission'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import VideocamIcon from '@mui/icons-material/VideocamOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import Dashboard from "../pages/dashboard/Home";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Users from "../pages/users/Users";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Profile from "../pages/profile/Profile";
import Team from "../pages/users/Team";
import FirstPage from "../pages/registration/FirstPage";
import Registration from "../pages/registration/Registration";

import Home from "../pages/home/Home";

import IP from "../pages/auth/index/IndexPage";
import ContactUs from '../pages/auth/ContactUs'

export const HomePage = Dashboard; //its shows as loagout page
export const IndexPage = Home; //its shows as loagout page

export const anonyMenuItems = [
  {
    name: "Index",
    exact: true,
    to: "/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Home />,
    // noMenu: true,
  },
  {
    name: "Login",
    exact: true,
    to: "/login/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Login hasForgot={"true"} />,
    // noMenu: true,
  },

  {
    name: "Register",
    exact: true,
    to: "/register/",
    icon: <PersonAddAlt1Icon />,
    component: <Register />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/reset/",
    icon: <PersonAddAlt1Icon />,
    component: <ForgotPassword title={"Reset Password"} submitLabel={"Reset"} />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Reset Password"} submitLabel={"Reset"} />,
    noMenu: true
  },
  {
    name: "Contact Us",
    exact: true,
    to: "/contact_us/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ContactUs title={"Contact Us"} phone={"9656291915"} email="sujithvs.think@gmail.com" address={"xyz"} />,
    noMenu: true
  },
  {
    name: "Job Login",
    exact: true,
    to: "/FirstPage/",
    icon: <SpaceDashboardSharpIcon />,
    component: <FirstPage/>,
    noMenu: true
  }
];

export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard/",
    icon: <SpaceDashboardSharpIcon sx={{ strokeWidth: 4 }} />,
    component: <Dashboard />,
  },
  {
    name: "Change Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Change Password"} submitLabel={"Change"} />,
    noMenu: true
  },
  {
    name: "Group",
    exact: true,
    to: "/group/",
    icon: <GroupsIcon />,
    component: <Group />,
  },
  {
    name: "User Permission",
    exact: true,
    to: "/user_permission/",
    icon: <AdminPanelSettingsIcon />,
    component: <UserPermission />,
  },
  {
    name: "Users",
    exact: true,
    to: "/users/",
    icon: <PeopleOutlined />,
    component: <Users />,
  },
  {
    name: "Team",
    exact: true,
    to: "/team/",
    icon: <PeopleOutlined />,
    component: <Team />,
    noMenu: true
  },
  {
    name: "Profile",
    exact: true,
    to: "/profile/",
    icon: <AccountBalanceIcon />,
    component: <Profile title={"Sujith VS"} />,
    noMenu: true
  },
  {
    name: "Registration",
    exact: true,
    to: "/registration/",
    icon: <CreateOutlinedIcon />,
    component: <Registration />,
    
  },
];
