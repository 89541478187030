import { Grid, Typography, Button, Box } from '@mui/material';
import React from 'react';
import { useDispatch } from "react-redux";
import { REQUESTS } from '../../redux/types';


export default function FirstPage() {
    let dispatch = useDispatch();

    const onSubmit = (data) => {
        console.log("data:", data);
        let action = data.type ? REQUESTS.USER.PUT.ACTION : REQUESTS.USER.POST.ACTION;
        dispatch({ type: action, payload: data });
    };

    return (
        <Box sx={{ flexGrow: 1, mt: 5, p: { xs: 2, md: 5 } }}>
            <Grid container spacing={2} alignItems="center">
                <Grid container spacing={1} alignItems="center" sx={{ pl: { xs: 2, md: 15 } }}>
                    <Grid item>
                        <img
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bab8cb33fc0a2111d5a1678b74129ffdd84d30dcb43fbe772163f87e1f630c69?apiKey=3cdd01a866254c9ba27f523a88497719"
                            alt="Logo"
                            loading="lazy"
                            style={{ width: '100%', maxWidth: '150px' }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">JobHuntly</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <img
                        loading="lazy"
                        src="https://uploads-ssl.webflow.com/6480217dd2b60074b15929c5/64896e2f14d2fc07ffa79526_Auth%20Page%20-%20Sidebar%20Illustration.png"
                        style={{ width: '100%', maxHeight: '700px', objectFit: 'cover' }}
                    />
                </Grid>

                <Grid item xs={12} md={7}>
                    <Grid container spacing={2} justifyContent="center" sx={{ mt: { xs: 2, md: 0 } }}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => onSubmit({ type: 'job seeker' })}
                            >
                                Job seeker
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button fullWidth variant="contained">Companies</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
